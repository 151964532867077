import * as React from 'react';

import {HandlePostMessage, AccessoryJobData} from 'types';

import {ReactComponent as CloseIcon} from 'images/close.svg';
import JobList from 'components/JobList/JobList';
import JobDetails from 'components/JobDetails/JobDetails';
import {getJobMatchSummaryMessage} from 'utils/job';
import css from './Jobs.module.css';

export type ButtonStyle = {backgroundColor?: string; color?: string};

export default function Jobs({
  matches,
  setShowMatchDetails,
  onSubmit,
  isQuestionNode = false,
  isLast = false,
  selectedMatch,
}: {
  matches: AccessoryJobData[];
  setShowMatchDetails: (arg: boolean) => void;
  onSubmit: HandlePostMessage;
  isLast?: boolean;
  isQuestionNode: boolean;
  selectedMatch?: AccessoryJobData | null;
}) {
  const [selectedJob, setSelectedJob] = React.useState(
    selectedMatch ? selectedMatch : matches?.[0] ?? null,
  );
  const [showJobDetails, setShowJobDetails] = React.useState(false);

  const handleHideJobsView = () => setShowMatchDetails(false);

  const matchMessage = getJobMatchSummaryMessage(matches.length);

  return (
    <>
      <div className={css.desktopContainer}>
        <div className={css.header} onClick={handleHideJobsView}>
          Back to chat
        </div>
        <div className={css.jobsContainer}>
          <div className={css.jobList}>
            <div className={css.matchingJobCount}>{matchMessage}</div>

            <JobList
              isMobile={false}
              matches={matches}
              setSelectedJob={setSelectedJob}
              setShowJobDetails={setShowJobDetails}
            />
          </div>
          <JobDetails
            isLast={isLast}
            isQuestionNode={isQuestionNode}
            selectedJob={selectedJob}
            setShowMatchDetails={setShowMatchDetails}
            onSubmit={onSubmit}
          />
        </div>
      </div>

      <div className={css.mobileContainer}>
        <div className={css.mobileContent}>
          <div className={css.mobileTopRow}>
            <CloseIcon onClick={handleHideJobsView} />
          </div>
          {showJobDetails ? (
            <JobDetails
              isLast={isLast}
              isQuestionNode={isQuestionNode}
              selectedJob={selectedJob}
              setShowMatchDetails={setShowMatchDetails}
              onSubmit={onSubmit}
            />
          ) : (
            <>
              <div className={css.matchingJobCount}>{matchMessage}</div>
              <JobList
                isMobile
                matches={matches}
                setSelectedJob={setSelectedJob}
                setShowJobDetails={setShowJobDetails}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
