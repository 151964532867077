import * as React from 'react';
import {Conversation, BrandingSettings} from 'types';
import findLastIndex from 'lodash/findLastIndex';
import plural from 'utils/plural';
import Unstyled from '../../@spaced-out/components/button/unstyled';
import classes from './BaseMinView.module.css';

const BaseMinView = ({
  flowBrandSettings,
  conversation,
  side,
  handleLogoClick,
}: {
  flowBrandSettings?: BrandingSettings;
  conversation: Conversation | null;
  side: 'left' | 'right';
  handleLogoClick?: () => void;
}) => {
  const conversationLength = conversation ? conversation.messages.length : 0;
  const lastOutgoingIndex = conversation
    ? findLastIndex(
        conversation.messages,
        ({direction}) => direction === 'outgoing',
      )
    : -1;

  let unansweredMessageCount = 0;
  if (lastOutgoingIndex >= 0) {
    if (lastOutgoingIndex + 1 !== conversation?.messages?.length) {
      unansweredMessageCount = conversation
        ? conversationLength - (lastOutgoingIndex + 1)
        : 0;
    }
  } else {
    unansweredMessageCount = conversationLength;
  }

  // @ts-ignore findLast is an array method, but not in ts lib
  const lastIncoming = (conversation?.messages ?? [])?.findLast(
    ({direction}: {direction: 'incoming' | 'outgoing'}) =>
      direction === 'incoming',
  );
  // TODO(Aman): Update Message(Incoming and Outgoing) to add type of message to it
  const conversationOver =
    lastIncoming && lastIncoming.type === 'conversation-end';

  if (
    conversationOver ||
    (lastOutgoingIndex !== -1 && unansweredMessageCount === 0)
  ) {
    return <div></div>;
  }

  const formattedGreeting = flowBrandSettings?.chatbot_initial_greeting
    ? flowBrandSettings.chatbot_initial_greeting.replaceAll(
        ' ',
        `\u00A0`, //nonbreaking space
      )
    : '';

  const minViewMessage =
    lastOutgoingIndex !== -1
      ? `${unansweredMessageCount} new ${plural(
          unansweredMessageCount,
          'message',
          'messages',
        )}!`
      : formattedGreeting;

  return (
    <Unstyled
      aria-live="polite"
      className={
        side === 'left'
          ? classes.inboundMinContainerLeft
          : classes.inboundMinContainerRight
      }
      style={
        flowBrandSettings && {
          color: flowBrandSettings.chatbot_font_color,
          border: `2px solid ${flowBrandSettings.color}`,
        }
      }
      onClick={handleLogoClick}
    >
      <p>{minViewMessage}</p>
    </Unstyled>
  );
};

export default BaseMinView;
