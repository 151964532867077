export function stringify(thing: unknown): string {
  return JSON.stringify(thing, function (key) {
    const value = this[key];
    if (value instanceof Date) {
      return {'@@constructor': 'Date', value: value.toString()};
    }
    return value;
  });
}

export function parse(data: string): any {
  return JSON.parse(data, (key, value) => {
    if (value instanceof Object) {
      const constructorName = Reflect.get(value, '@@constructor');
      const stringValue = Reflect.get(value, 'value');
      if (constructorName) {
        return new (globalThis as any)[constructorName](stringValue);
      }
    }
    return value;
  });
}
