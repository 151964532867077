import * as React from 'react';
import {AccessoryJobData, HandlePostMessage} from 'types';
// @ts-ignore
import {Button} from '@spaced-out/ui-design-system/lib/components/Button';
import {ReactComponent as MapIcon} from 'images/map-icon.svg';
import classes from './JobDetails.module.css';

const JobDetails = ({
  isLast = false,
  isQuestionNode,
  selectedJob,
  setShowMatchDetails,
  onSubmit,
}: {
  isLast?: boolean;
  isQuestionNode: boolean;
  selectedJob: AccessoryJobData | null;
  setShowMatchDetails: (arg: boolean) => void;
  onSubmit: HandlePostMessage;
}) => {
  const handleClickApply = () => {
    if (selectedJob) {
      const {title, external_job_id} = selectedJob;
      onSubmit(title, external_job_id);
      setShowMatchDetails(false);
    }
  };

  return (
    <div className={classes.jobDetailContainer}>
      <div className={classes.jobDetail}>
        <div className={classes.jobDetailTitle}>{selectedJob?.title}</div>
        <div className={classes.jobDetailSubtitle}>
          <MapIcon />
          {selectedJob?.location}
        </div>
        <div
          className={classes.jobDetailDescription}
          dangerouslySetInnerHTML={{
            __html: selectedJob?.description ?? '',
          }}
        />
      </div>
      <div className={classes.applyNow}>
        {selectedJob?.sense_url &&
          (isQuestionNode ? (
            <Button
              rel="noopener"
              isFluid={true}
              onClick={handleClickApply}
              disabled={!isLast}
            >
              {'Apply now'}
            </Button>
          ) : (
            // Verify this during question node change
            <a href={selectedJob?.sense_url} target="_blank" rel="noreferrer">
              <Button isFluid={true}>{'Apply now'}</Button>
            </a>
          ))}
      </div>
    </div>
  );
};

export default JobDetails;
