import * as React from 'react';

export function useClickaway(
  onChange?: (val: boolean) => any,
  shouldCancel?: (evt: MouseEvent) => boolean,
) {
  const [isOpen, setIsOpen] = React.useState(false);

  const [cancelNext, setCancelNext] = React.useState(false);

  const maybeClickAway = (evt: MouseEvent) => {
    if (shouldCancel) {
      const next = shouldCancel(evt);
      onChange?.(next);
      return next;
    }
    if (cancelNext) {
      setCancelNext(false);
      return isOpen;
    } else if (isOpen) {
      return false;
    }
    return isOpen;
  };

  const handleDocumentClick = React.useCallback(
    (evt: MouseEvent) => {
      if (isOpen === false) {
        return;
      }
      const nextState = maybeClickAway(evt);
      onChange?.(nextState);
      setIsOpen(nextState);
    },
    [isOpen, cancelNext],
  );

  React.useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isOpen, cancelNext]);
  return {
    isOpen,
    open: () => {
      setIsOpen(true);
      setCancelNext(true);
    },
    cancelNext: () => setCancelNext(true),
    toggle: () => {
      setIsOpen(prev => !prev);
      setCancelNext(true);
    },
  };
}
