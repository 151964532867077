import * as React from 'react';
import {AccessoryJobData} from 'types';
import classes from './JobList.module.css';

const JobList = ({
  isMobile,
  matches,
  setSelectedJob,
  setShowJobDetails,
}: {
  isMobile: boolean;
  matches?: AccessoryJobData[];
  setSelectedJob: (job: AccessoryJobData) => void;
  setShowJobDetails: (arg: boolean) => void;
}) => (
  <>
    {matches &&
      matches.map(({title, location, job_id}, index) => (
        <div
          key={job_id}
          aria-label={`Detail for ${title}`}
          className={classes.jobSummary}
          onClick={() => {
            setSelectedJob(matches[index]);
            if (isMobile) {
              setShowJobDetails(true);
            }
          }}
        >
          <div className={classes.jobTitle}>{title}</div>
          <div>{location}</div>
        </div>
      ))}
  </>
);

export default JobList;
