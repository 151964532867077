export function makeSearchParams(query: {
  [key: string]: unknown,
}): URLSearchParams {
  const params = new URLSearchParams();

  for (const key of Object.keys(query).sort()) {
    let value = query[key];

    if (value !== undefined) {
      const stringValue = typeof value !== 'string' ?
        JSON.stringify(value) : value;

      if (value != null) {
        params.append(key, stringValue);
      }
    }
  }

  return params;
}

/**
 * Serializes a query object to be used as a unique identifier / key.
 * Sorts the keys to ensure that the same query object always returns
 * the same serialized string.
 *
 * NOTE (kyle): possible we'll eventually be able to replace this
 * with native URLSearchParams.
 */

// TODO (kyle): this param should not be optional.
export function serializeQuery(
  query: {
    [key: string]: unknown,
  } = {},
): string {
  return makeSearchParams(query).toString();
}

