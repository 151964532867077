import * as React from 'react';

export const TextInputContext = React.createContext<{
  value: string;
  jsonValue: string[];
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setJsonValue: React.Dispatch<React.SetStateAction<string[]>>;
}>({
  value: '',
  setValue: _v => {},
  jsonValue: [],
  setJsonValue: _v => {},
});
