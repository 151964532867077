import * as React from 'react';
import {
  ApiConversation,
  Conversation,
  ChatbotDispatch,
  ChatbotState,
  ChatId,
  BrandingSettings,
  InboundDisplayMode,
} from 'types';
import {useWindowWidth} from 'context/Responsive';
import Header from 'components/Header/Header';
import DocumentHead from 'components/DocumentHead/DocumentHead';
import Chatbot from 'components/Chatbot/Chatbot';
import {ReactComponent as SenseLogo} from 'images/sense-logo.svg';
import last from 'lodash/last';
import api from 'utils/bot-api';
import {playChimeAndDispatch} from 'utils/audio';
import classes from './ConversationView.module.css';

const ConversationView = ({
  chatId,
  conversation,
  state,
  dispatch,
  chatbotApiV2,
  handleLogoClick,
  isPreview,
  inboundDisplayMode,
  maximizeInboundDisplay,
  flowBrandSettings,
  isMobile,
  chatbotFaqEnabled,
  qnOverSms = false,
  jobSelected = false,
}: {
  chatId: ChatId;
  conversation: Conversation;
  state: ChatbotState;
  dispatch: ChatbotDispatch;
  chatbotApiV2: boolean;
  isPreview: boolean;
  handleLogoClick?: () => void;
  inboundDisplayMode?: InboundDisplayMode;
  maximizeInboundDisplay: () => void;
  flowBrandSettings?: BrandingSettings;
  isMobile?: boolean;
  chatbotFaqEnabled?: boolean;
  qnOverSms?: boolean;
  jobSelected?: boolean;
}) => {
  const {context: botContext} = useWindowWidth();
  const {
    branding_settings: {logo, display_name, favicon},
  } = conversation;

  const handlePostMessage = React.useCallback(
    (text: string, payload?: string | string[]) => {
      dispatch({
        type: 'post_message',
        payload: text,
      });

      window?.gtag?.('event', 'sent_message');

      const body_and_json = state.isSms
        ? // sms message previews can't consume json
          {body: Array.isArray(payload) ? payload.join(',') : payload ?? text}
        : {
            body_json: Array.isArray(payload) ? payload : undefined,
            body: !Array.isArray(payload) ? payload ?? text : undefined,
          };

      const params: {
        session_id: string;
        body?: string;
        code?: string;
        flow_id?: string;
        body_json?: string[];
        preview: boolean;
        channel: 'sms' | 'web';
      } = {
        ...body_and_json,
        session_id: state.sessionId,
        preview: isPreview,
        channel: state.isSms ? 'sms' : 'web',
      };

      if (chatId.type === 'chat_code') {
        params.code = chatId.value;
      } else {
        params.flow_id = chatId.value;
      }

      const request = api.post('/send', params);

      request
        .then((conversation: ApiConversation) =>
          playChimeAndDispatch(
            !state.muteChime &&
              Boolean(flowBrandSettings?.chatbot_audio_enabled),
            () => {
              if (conversation == null) {
                // pass
                // this only happens when we send a response to the LAT
                // in these cases we expect to wait for a reply message on the
                // socket and not from /send
                // TODO(marcos): verify chatbot 'mode' is LAT in this case
                // or check status code
              } else if (conversation.messages != null) {
                dispatch({type: 'receive_conversation', payload: conversation});
              }
            },
          ),
        )
        .catch(error => {
          dispatch({type: 'receive_conversation_error', payload: error});
        });
    },
    [
      dispatch,
      state.isSms,
      state.sessionId,
      state.muteChime,
      isPreview,
      chatId.type,
      chatId.value,
      flowBrandSettings,
    ],
  );

  React.useEffect(() => {
    if (state.mode === 'lat_terminating') {
      handlePostMessage('', 'HAND_BACK::');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.mode]);

  let content;
  // don't show end chat if in preview mode
  content = (
    <div className={classes.content}>
      <Chatbot
        chatId={chatId}
        state={state}
        dispatch={dispatch}
        chatbotApiV2={chatbotApiV2}
        isPreview={isPreview}
        mode={handleLogoClick ? 'bot' : undefined}
        branding_settings={conversation.branding_settings ?? {}}
        inboundDisplayMode={inboundDisplayMode}
        maximizeInboundDisplay={maximizeInboundDisplay}
        chatbotFaqEnabled={chatbotFaqEnabled}
        onPostMessage={handlePostMessage}
        qnOverSms={qnOverSms}
        jobSelected={jobSelected}
      />
    </div>
  );

  const lastMessage =
    Array.isArray(state?.conversation?.messages) &&
    last(state?.conversation?.messages); // ?. for typescript
  const inputDisabled =
    state.isDone ||
    !lastMessage ||
    lastMessage.direction !== 'incoming' ||
    lastMessage.type === 'scheduler';

  return (
    <>
      <DocumentHead>
        {favicon && <link rel="icon" href={favicon} />}
      </DocumentHead>

      <Header
        logo={logo}
        name={conversation.branding_settings?.chatbot_bot_name}
        description={conversation.branding_settings?.chatbot_bot_tagline}
        displayName={display_name}
        handleLogoClick={handleLogoClick}
        dispatch={dispatch}
        state={state}
        isMobile={isMobile}
        chatbotFaqEnabled={chatbotFaqEnabled}
        inputDisabled={inputDisabled}
        onPostMessage={handlePostMessage}
      />

      {botContext === 'desktop' ? (
        <>
          <div className={classes.bg}>{content}</div>
          <div className={classes.footer}>
            {!state.agencyConfig?.chatbot_white_label_frontend && (
              <>
                <span className={classes.footerText}>powered by</span>
                <div className={classes.footerLogo}>
                  <SenseLogo />
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        content
      )}
    </>
  );
};

export default ConversationView;
