import * as React from 'react';

export type ThunkDispatch<S, A> = (
  action:
    | A
    | ((dispatch: React.Dispatch<A>, stateRef: {current: S}) => unknown),
) => Promise<void>;

export function useThunkReducer<S, A extends {[key: string]: any}>(
  reducer: React.Reducer<S, A>,
  initialState: S,
): [S, ThunkDispatch<S, A>] {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const stateRef = React.useRef(state);

  stateRef.current = state;

  const thunkDispatch: ThunkDispatch<S, A> = React.useCallback(
    async (
      action:
        | A
        | ((dispatch: React.Dispatch<A>, stateRef: {current: S}) => unknown),
    ) => {
      if (typeof action === 'function') {
        await (action as any)(dispatch, stateRef);
      } else {
        dispatch(action);
      }
    },
    [dispatch],
  );

  return [state, thunkDispatch];
}
