import * as React from 'react';

import {ChatbotState, ChatbotDispatch} from 'types';

export const ChatbotContext = React.createContext<{
  state?: ChatbotState;
  dispatch?: ChatbotDispatch;
}>({});

export function useChatbotState() {
  return React.useContext(ChatbotContext);
}
