/**
 * because params are liable to be found in either the search params or as the values
 * of dom attributes, we assume they're strings (they are passed through to the bot)
 */
type ParamValueMapping = {[customerVariableName: string]: string};

/**
 * this mapping is always external variable name -> custom variable name and we send it
 * down in the sourcing flow dict.
 */
type FlowParamMapping = {[externalVariableName: string]: string};

/**
 * This mapping has the same external variable keys, but the values are the detected
 * values on the page. This CustomParamMapping is what gets sent down to the bot
 */
type CustomParamMapping = FlowParamMapping;

const getMatchingQueryParams = (names: string[]): {[key: string]: string} => {
  /**
   * given a list of names, find matching values in the host page's url search parameters
   *
   * @returns {[key: string]: string} - an object keyed by the names passed in
   */
  const params = new URL(window.location.href).searchParams;
  const lowerCaseParams = new Map();
  for (const [key, value] of params.entries()) {
    lowerCaseParams.set(`${key.toLowerCase()}`, value);
  }

  const vals: Array<[string, string]> = names
    .map(name => {
      const lowerCaseName = name.toLowerCase();
      if (lowerCaseParams.has(lowerCaseName)) {
        const val = lowerCaseParams.get(lowerCaseName);
        if (name != null && val != null) {
          return [name, val];
        }
      }
      return null;
    })
    .filter(pair => Array.isArray(pair)) as [string, string][];

  if (vals.length > 0) {
    return Object.fromEntries(vals);
  }
  return {};
};

const mapFlowParams = (
  flowParamMapping: FlowParamMapping,
  foundParams: ParamValueMapping,
): CustomParamMapping | undefined => {
  const entries = Object.entries(flowParamMapping)
    .map(([extKey, customKey]) => {
      if (foundParams[customKey] != null) {
        return [extKey, foundParams[customKey]];
      } else {
        return null;
      }
    })
    .filter(entry => Array.isArray(entry)) as [string, string][];

  if (entries.length > 0) {
    return Object.fromEntries(entries);
  }
  return undefined;
};

export const getCustomParams = (
  flowParamMapping: FlowParamMapping,
): CustomParamMapping | undefined => {
  const paramNames: string[] = Object.values(flowParamMapping);
  const matchedParams = getMatchingQueryParams(paramNames);
  return mapFlowParams(flowParamMapping, matchedParams);
};

export const nonSenseParams = (
  params: URLSearchParams,
): {[key: string]: string} => {
  const customParamKeys = ['job_id', 'job_title', 'application_source'];

  const filtered = [...params.entries()].filter(([key]) =>
    customParamKeys.includes(key),
  );
  const mapped = filtered.map(([key, val]) => [`external/${key}`, val]);
  return Object.fromEntries(mapped);
};
