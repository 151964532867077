import {Message, MessageBase} from 'types';

export function getMessageBlock(message: Message) {
  return message.direction === 'incoming' ? message : null;
}

export function extractAltTextFromGifEntity(
  url: string,
  textDraftJson: MessageBase['text_draft_json'],
): string {
  const entityMap = textDraftJson?.entityMap || {};
  const matchedEntity = Object.values(entityMap).filter(
    entity => entity?.type === 'GIF' && entity?.data?.url === url,
  )?.[0];
  return matchedEntity?.data?.altText || '';
}
