const chime = require('../sounds/chatbot-chime.mp3');

const playChime = () => {
  new Audio(chime).play().catch(err => {
    console.warn(err);
  });
};
export const playChimeAndDispatch = (
  audioOn: boolean,
  dispatchAction: () => void,
) => {
  if (audioOn) {
    playChime();
  }
  dispatchAction();
};

export default playChime;
