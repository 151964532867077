import * as React from 'react';
import Linkify from 'linkify-it';

const linkify = Linkify();

export function addLinks<T>(
  text: string,
  formatLink: (match: Linkify.Match) => T | undefined,
): Array<string | T> {
  const matches = linkify.match(text) || [];

  const result = [];
  let index = 0;
  for (const match of matches) {
    result.push(text.slice(index, match.index));
    const linkText = formatLink(match);
    result.push(linkText ? linkText : match.raw);
    index = match.lastIndex;
  }
  result.push(text.slice(index));

  return result;
}

export function addHtmlLinks(text: string): string {
  return addLinks(
    text,
    match =>
      `<a href="${match.url}" target="_blank" rel="noopener noreferrer">${match.raw}</a>`,
  ).join('');
}

export function createHtmlLink(match: Linkify.Match): string {
  return `<a href="${match.url}" target="_blank" rel="noopener noreferrer">${match.raw}</a>`;
}

export function LinkifyView({
  children,
}: {
  children: string;
}): React.ReactElement {
  return <>{addLinks(children, formatReactLinks)}</>;
}

function formatReactLinks(match: Linkify.Match) {
  return (
    <a
      href={match.url}
      key={String(match.index)}
      target="_blank"
      rel="noopener noreferrer"
    >
      {match.raw}
    </a>
  );
}
