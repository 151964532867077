import * as React from 'react';

import {useChatbotState} from 'context/ChatbotState';

import {ReactComponent as CloseIcon} from 'images/close.svg';

import css from './File.module.css';

export default function File({
  setShowFilePreview,
}: {
  setShowFilePreview: (arg: boolean) => void;
}) {
  const {state} = useChatbotState();
  const file = state?.file;
  const src = file ? URL.createObjectURL(file) : '';

  const isImage = file?.type.includes('image');
  const isDoc = file?.name
    ?.split('.')
    ?.pop()
    ?.includes('doc'); //typescript required the 3 ?s

  const handleHideFilePreview = () => setShowFilePreview(false);

  const Preview = () => {
    return isImage ? (
      <img src={src} />
    ) : isDoc ? (
      <a href={src} download={file?.name ?? ''} className={css.docDownload}>
        download to view the file
      </a>
    ) : (
      <object data={src} />
    );
  };

  return (
    <>
      <div className={css.desktopContainer}>
        <div className={css.header} onClick={handleHideFilePreview}>
          Back to chat
        </div>
        <Preview />
      </div>

      <div className={css.mobileContainer}>
        <div className={css.mobileContent}>
          <div className={css.mobileTopRow}>
            <CloseIcon onClick={handleHideFilePreview} />
          </div>
          <Preview />
        </div>
      </div>
    </>
  );
}
