// NOTE (kyle): this is a brittle way of testing for ie taken from here
// https://stackoverflow.com/questions/31757852/how-can-i-detect-internet-explorer-ie-and-microsoft-edge-using-javascript/32107845
//
// If we ever want better browser detection, platform.js looks like a good option.

let ie: number | string | undefined;

if (typeof window !== 'undefined' && window.navigator) {
  const {navigator: {userAgent}} = window;

  if (/MSIE 10/i.test(userAgent)) {
    ie = 10;
  } else if (/MSIE 9/i.test(userAgent)) {
    ie = 9;
  } else if (/rv:11.0/i.test(userAgent)) {
    ie = 11;
  } else if (/Edge\/\d./i.test(userAgent)) {
    ie = 'edge';
  }
}

export default ie;
