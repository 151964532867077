import * as React from 'react';

import {ApiError} from 'utils/api';
import {ChatbotApiErrorResponse} from 'utils/bot-api';
import makeClassNameComponent from 'utils/makeClassNameComponent';

import {CloseChat} from 'components/EndChat/EndChat';

import {ReactComponent as RevaError} from 'images/reva-error.svg';
import {ReactComponent as RevaNotFound} from 'images/reva-not-found.svg';
import {ReactComponent as RevaAlert} from 'images/reva-alert.svg';

import css from './Loading.module.css';

export default function Loading({color}: {color?: string}) {
  return (
    <Bg
      className={color ? '' : css.blueBg}
      style={color ? {backgroundColor: color} : {}}
    >
      <RevaLogo color={color ? color : '#007FAF'} />
    </Bg>
  );
}

const Bg = makeClassNameComponent(css.bg);

export function ErrorPage({error}: {error: ApiError | string}) {
  if (typeof error === 'string') {
    return <Invalid message={error} />;
  } else {
    const status = error.response.status;
    if (status < 500) {
      // @ts-ignore we have to assume all errors from the chatbot server
      // are of this format.
      const responseBody: ChatbotApiErrorResponse = error.responseBody;

      const errors = new Set(responseBody?.errors || []);

      if (errors.has('ChatbotCodeExpired')) {
        return <Expired />;
      } else if (errors.has('ChatbotFlowNotBuilt')) {
        return <NotBuilt />;
      } else if (errors.has('ChatbotCodeCompleted')) {
        return (
          <CloseChat copy="This conversation has been completed. If you think this is a mistake, please verify the URL." />
        );
      }
      return <NotFound />;
    }

    if (status === 503) {
      return <Maintenance />;
    }

    return <Error500 />;
  }
}

export function NotFound() {
  return (
    <Bg>
      <Center>
        <RevaNotFound />
        <Content>
          <h1>404</h1>
          <h2>Sorry, bot not found.</h2>
          <p>
            We couldn't find your conversation. Please check the url and try
            again.
          </p>
        </Content>
      </Center>
    </Bg>
  );
}

export function Invalid({message}: {message: string}) {
  return (
    <Bg>
      <Center>
        <RevaNotFound />
        <Content>
          <h2>Invalid Application</h2>
          <p>{message}</p>
        </Content>
      </Center>
    </Bg>
  );
}

export function Expired() {
  return (
    <Bg>
      <Center>
        <RevaNotFound />
        <Content>
          <h1>Expired</h1>
          <h2>Sorry, this bot has expired.</h2>
          <p>
            If you were sent this link recently, please check the url and try
            again.
          </p>
        </Content>
      </Center>
    </Bg>
  );
}

export function NotBuilt() {
  return (
    <Bg>
      <Center>
        <RevaNotFound />
        <Content>
          <h1>Not Available</h1>
          <h2>
            Sorry, the chat you are trying to access is currently in the draft
            mode and is not yet available for use.
          </h2>
          <p>
            If you were sent this link recently, please check the url and try
            again.
          </p>
        </Content>
      </Center>
    </Bg>
  );
}

export function Maintenance() {
  return (
    <Bg>
      <Center>
        <RevaAlert width={322} height={322} />
        <Content>
          <h2>The chatbot is currently undergoing maintenance.</h2>
          <p>
            Please visit our{' '}
            <a
              href="https://status.sensehq.com"
              target="_blank"
              rel="noreferrer"
            >
              status page
            </a>{' '}
            for more information
          </p>
        </Content>
      </Center>
    </Bg>
  );
}

export function Error500() {
  return (
    <Bg>
      <Center>
        <RevaError />
        <Content>
          <h1>500</h1>
          <h2>Sorry, something went wrong.</h2>
          <p>Please try again. We'll be back soon.</p>
        </Content>
      </Center>
    </Bg>
  );
}

const Center = makeClassNameComponent(css.center);
const Content = makeClassNameComponent(css.content);

const RevaLogo = ({color}: {color: string}) => {
  return (
    <svg
      width="322"
      height="322"
      viewBox="0 0 322 322"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0625 140.875C10.0625 135.317 14.5677 130.812 20.125 130.812H150.938C173.167 130.812 191.188 148.833 191.188 171.062V251.562C191.188 279.349 168.662 301.875 140.875 301.875H20.125C14.5677 301.875 10.0625 297.37 10.0625 291.812V140.875Z"
        fill="#F7F7F7"
      />
      <path
        d="M55.3438 98.1094C55.3438 93.9414 51.9649 90.5625 47.7969 90.5625C43.6289 90.5625 40.25 93.9414 40.25 98.1094V133.328C40.25 137.496 43.6289 140.875 47.7969 140.875C51.9649 140.875 55.3438 137.496 55.3438 133.328V98.1094Z"
        fill="#F7F7F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.2778 196.219C70.2778 190.661 74.783 186.156 80.3403 186.156C85.8977 186.156 90.4028 190.661 90.4028 196.219V216.344C90.4028 221.901 85.8977 226.406 80.3403 226.406C74.783 226.406 70.2778 221.901 70.2778 216.344V196.219ZM91.5669 256.968C87.4481 254.251 85.4002 250.752 85.3741 246.787C85.4734 244.187 86.6609 243.072 87.6303 242.687C88.681 242.271 90.9191 242.157 93.9265 244.557L94.0676 244.67C97.541 247.443 102.606 251.486 109.292 251.486C109.481 251.486 109.664 251.491 109.843 251.499L110.112 251.512L110.381 251.496C110.481 251.49 110.584 251.487 110.688 251.487H130.813C133.591 251.487 135.844 253.74 135.844 256.518C135.844 259.298 133.591 261.55 130.813 261.55H110.688C110.604 261.55 110.521 261.548 110.439 261.543L110.235 261.534L110.029 261.541C109.852 261.548 109.671 261.551 109.485 261.551C102.062 261.551 95.8037 259.762 91.5669 256.968ZM150.938 186.156C145.38 186.156 140.875 190.661 140.875 196.219V216.344C140.875 221.901 145.38 226.406 150.938 226.406C156.495 226.406 161 221.901 161 216.344V196.219C161 190.661 156.495 186.156 150.938 186.156Z"
        fill={color}
      />
      <path
        d="M168.646 94.8629C158.148 90.1537 150.812 79.4055 150.812 66.8997V50.6303C150.812 33.7826 164.126 20.125 180.549 20.125H249.877C266.3 20.125 279.612 33.7826 279.612 50.6303V66.8997C279.612 83.7474 266.3 97.405 249.877 97.405H195.431L168.614 116.725L168.646 94.8629Z"
        fill="#F7F7F7"
      />
      <path
        className="dot"
        d="M182.386 68.907C188.461 68.907 193.385 63.9821 193.385 57.9071C193.385 51.832 188.461 46.907 182.386 46.907C176.311 46.907 171.386 51.832 171.386 57.9071C171.386 63.9821 176.311 68.907 182.386 68.907Z"
        fill={color}
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0 10; 0 -10; 0 10"
          dur="1s"
          repeatCount="indefinite"
          calcMode="spline"
          keySplines="0.4 0 0.2 1; 0.4 0 0.2 1"
        />
      </path>
      <path
        className="dot"
        d="M215.35 68.907C221.425 68.907 226.35 63.9821 226.35 57.9071C226.35 51.832 221.425 46.907 215.35 46.907C209.274 46.907 204.349 51.832 204.349 57.9071C204.349 63.9821 209.274 68.907 215.35 68.907Z"
        fill={color}
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          begin="0.2s"
          values="0 10; 0 -10; 0 10"
          dur="1s"
          repeatCount="indefinite"
          calcMode="spline"
          keySplines="0.4 0 0.2 1; 0.4 0 0.2 1"
        />
      </path>
      <path
        className="dot"
        d="M248.309 68.907C254.384 68.907 259.308 63.9821 259.308 57.9071C259.308 51.832 254.384 46.907 248.309 46.907C242.233 46.907 237.308 51.832 237.308 57.9071C237.308 63.9821 242.233 68.907 248.309 68.907Z"
        fill={color}
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          begin="0.4s"
          values="0 10; 0 -10; 0 10"
          dur="1s"
          repeatCount="indefinite"
          calcMode="spline"
          keySplines="0.4 0 0.2 1; 0.4 0 0.2 1"
        />
      </path>
      <rect x="0.5" y="0.5" width="321" height="321" stroke={color} />
    </svg>
  );
};
