import React from 'react';

const ChatbotDefaultAvatar = ({
  color,
  className,
}: {
  color?: string;
  className: string;
}) => (
  <svg
    className={className}
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="25.5"
      cy="25.5"
      r="24.5"
      fill="white"
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M16.8542 18.1354C16.8542 17.5683 17.3092 17.1086 17.8704 17.1086H31.081C33.3259 17.1086 35.1458 18.9474 35.1458 21.2155V29.4294C35.1458 32.2646 32.8709 34.563 30.0648 34.563H17.8704C17.3092 34.563 16.8542 34.1033 16.8542 33.5363V18.1354Z"
      fill={color}
    />
    <path
      d="M21.4254 13.77C21.4254 13.3448 21.0841 13 20.6632 13C20.2423 13 19.9011 13.3448 19.9011 13.77V17.3636C19.9011 17.7889 20.2423 18.1336 20.6632 18.1336C21.0841 18.1336 21.4254 17.7889 21.4254 17.3636V13.77Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9362 23.7637C22.9362 23.1966 23.3912 22.7369 23.9524 22.7369C24.5137 22.7369 24.9686 23.1966 24.9686 23.7637V25.8171C24.9686 26.3842 24.5137 26.8438 23.9524 26.8438C23.3912 26.8438 22.9362 26.3842 22.9362 25.8171V23.7637ZM25.0862 29.9622C24.6702 29.685 24.4634 29.328 24.4608 28.9234C24.4708 28.6581 24.5907 28.5443 24.6886 28.5051C24.7947 28.4626 25.0208 28.451 25.3245 28.6959L25.3387 28.7074C25.6895 28.9903 26.201 29.4029 26.8762 29.4029C26.8953 29.4029 26.9138 29.4034 26.9319 29.4042L26.959 29.4055L26.9862 29.4039C26.9963 29.4033 27.0067 29.403 27.0171 29.403H29.0495C29.3301 29.403 29.5576 29.6328 29.5576 29.9163C29.5576 30.1999 29.3301 30.4297 29.0495 30.4297H27.0171C27.0087 30.4297 27.0004 30.4295 26.992 30.4291L26.9714 30.4281L26.9507 30.4289C26.9328 30.4295 26.9145 30.4298 26.8957 30.4298C26.1461 30.4298 25.514 30.2473 25.0862 29.9622ZM31.0819 22.7369C30.5207 22.7369 30.0657 23.1966 30.0657 23.7637V25.8171C30.0657 26.3842 30.5207 26.8438 31.0819 26.8438C31.6432 26.8438 32.0981 26.3842 32.0981 25.8171V23.7637C32.0981 23.1966 31.6432 22.7369 31.0819 22.7369Z"
      fill="white"
    />
  </svg>
);

export default ChatbotDefaultAvatar;
