import {Api} from 'utils/api';

const senseStagingDomains: {[key: string]: string} = {
  'stage-1': 'stage-chatbot',
  'stage-02': 'stage-chatbot-02',
  'stage-03': 'stage-chatbot-03',
  'stage-04': 'stage-chatbot-04',
  'stage-05': 'stage-chatbot-05',
  'stage-06': 'stage-chatbot-06',
};

function apiOriginFromLocation(origin: string): string {
  const hostname = new URL(origin).hostname;
  const agency = hostname.split('.')[0];
  const stage = hostname.split('.')[1];

  if (origin.includes('stage')) {
    return `https://chatbot-faqs.${senseStagingDomains[stage]}.sensehq.co`;
  } else {
    if (!agency) {
      throw new Error(`Unable to get agency for origin: ${origin}`);
    }
    return `https://chatbot-faqs.sensehq.co`;
  }
}

export const faqApi = new Api(
  `${apiOriginFromLocation(window.location.origin)}/api/faqs`,
);

export const faqApiV2 = new Api(
  `${apiOriginFromLocation(window.location.origin)}/api/v2/faqs`,
);
