/**
 * _ready behaves like $(fn) or $.ready(fn), execting callback either once
 * the page loads, or right now if the page has alreaded loaded.
 * we need this because the bot may block on calling this until well after the
 * page has loaded, but many of reciteme's snippets assume that the initialization
 * happens before DCL.
 * @param {() => void} callback a plain anonymous function that receives no arguments
 */
function _ready(callback: () => void) {
  // this is the case if we are being loaded from a script in the <body />
  // the DCL event won't ever be called, so we can call it directly
  if (document.readyState !== 'loading') {
    callback();
  } else {
    // only once DCL has been called do we add the widget otherwise
    // the widget might be added while the page is still in an awkward state
    // and could cause the embed to fail
    window.addEventListener('DOMContentLoaded', () => {
      callback();
    });
  }
}

// this is slight modification of the code reciteme provides here
// https://knowledge.reciteme.com/knowledge/i-frame
//
export function embedReciteMe(serviceKey: string) {
  var serviceUrl = '//api.reciteme.com/asset/js?key=';

  const options = {
    FrameUpdater: {
      enabled: true,
    },

    Gui: {Notification: {suppress: true}},
  };

  const autoLoad = false;
  const enableFragment = '#reciteEnable';

  let loaded: string[] = [],
    frag = !1;
  window.location.hash === enableFragment && (frag = !0);

  function loadScript(scriptSrc: string, callback?: () => void) {
    var scriptEl = document.createElement('script');
    scriptEl.type = 'text/javascript';
    scriptEl.onload = function() {
      callback?.();
    };
    scriptEl.src = scriptSrc;
    document.getElementsByTagName('head')[0].appendChild(scriptEl);
  }
  // get some cookie value
  function _rc(cookieName: string) {
    cookieName += '=';
    for (var b = document.cookie.split(';'), a = 0; a < b.length; a++) {
      for (var d = b[a]; ' ' == d.charAt(0); ) d = d.substring(1, d.length);
      if (0 == d.indexOf(cookieName))
        return d.substring(cookieName.length, d.length);
    }
    return null;
  }
  // loaded is an empty array, and serviceurl/key are both strings.
  function loadService(callback?: () => void) {
    // this confounding loop exists to check if this function has been called
    // yet. it uses a loop/array assuming that perhaps somebody else will use
    // the loaded array to initialize their loaded state
    for (var b = serviceUrl + serviceKey, a = 0; a < loaded.length; a++)
      if (loaded[a] == b) return;
    loaded.push(b);
    loadScript(serviceUrl + serviceKey, function() {
      // reciteme adds reciteLoaded to window as a fn you can use to execute
      // some functionality once recite has loaded
      window._reciteLoaded?.();
      // execute callback if passed in
      callback?.();

      window.Recite?.load(options);
      window.Recite?.Event?.subscribe('Recite:load', function() {
        window.Recite?.enable();
      });
    });
  }

  // not sure if these cookie checks actually work anymore but
  // they're part of the original snippet
  'true' == _rc('Recite.Persist') && loadService();
  ((autoLoad && 'false' != _rc('Recite.Persist')) || frag) && loadService();

  // create a frame communicator
  // the original version of this consumed the dom event fired by DCL,
  // but never used it.
  function reciteFrameCommunicator() {
    let isParent = false;
    let initialPayload = {data: 'recite_frame_enabler'};
    if (window.top == window.self) {
      isParent = true;
    } else initialPayload = {data: 'recite_frame_parent_communicator'};
    window.top?.postMessage(JSON.stringify(initialPayload), '*');
    window.addEventListener('message', function(msgEvt) {
      if (typeof msgEvt.data !== 'string') {
        // recite only emits string messages, not json objects directly
        return;
      }
      let parsedMessage;
      try {
        parsedMessage = JSON.parse(msgEvt.data);
      } catch (err) {
        console.error(
          'recite/sensehq: could not parse message from window as json',
          err,
        );
        return;
      }
      'undefined' != typeof msgEvt.source &&
        (isParent && 'recite_frame_parent_communicator' == parsedMessage.data
          ? 'undefined' != typeof window.Recite &&
            null != msgEvt.source &&
            // @ts-ignore -- this use of * appears to be a bug
            msgEvt.source.postMessage(JSON.stringify(initialPayload), '*')
          : isParent ||
            'recite_frame_enabler' != parsedMessage.data ||
            loadService());
    });
  }

  // execute reciteFromeCommunicator as soon as possible
  _ready(() => {
    reciteFrameCommunicator();
  });

  // Attach the handler to the "recite button"
  _ready(() => {
    // it's not clear that this button exists when we embed reciteme in an iframe
    document.getElementById('myButton')?.addEventListener('click', function() {
      loadService();

      return false;
    });
  });
}
