import * as React from 'react';
import omitBy from 'lodash/omitBy';
import {
  ChatbotState,
  ApiConversation,
  ApiConversationStart,
  ChatId,
  BrandingSettings,
  InboundDisplayMode,
  ApiMessage,
} from 'types';
import api, {senseApi, genericBotApi} from 'utils/bot-api';
import {faqApi} from 'utils/faq-api';
import {stringify, parse} from 'utils/hot-parser';
import playChime, {playChimeAndDispatch} from 'utils/audio';
import {reduceChatbot, initialState} from 'reducer';
import Loading, {ErrorPage} from 'components/Loading/Loading';
import {BrandingContext} from 'context/Branding';
import {ChatbotContext} from 'context/ChatbotState';
import {useThunkReducer} from 'hooks/useThunkReducer';
import {useResourceApi} from 'hooks/useApi';
import {useLATWS} from 'utils/lat-ws';
import BaseMinView from 'components/BaseMinView/BaseMinView';
import ConversationView from 'components/ConversationView/ConversationView';

// deciding default state based on NODE_ENV and chatbot local state
let defaultState: ChatbotState;

try {
  const isDev = process.env.NODE_ENV === 'development';
  const hasExistingLocalState = !!localStorage.chatbotState;

  defaultState = isDev
    ? hasExistingLocalState
      ? parse(localStorage.chatbotState)
      : initialState
    : initialState;
} catch (err) {
  console.error(err);
  defaultState = initialState;
}

const debugConversation = {
  first_name: 'Kyle',
  conversation_id: 'ie6sZu5TN0oMf4XtjN27Ua',
  flow: {
    show_top_faqs: false,
  },
  branding_settings: {
    chatbot_avatar:
      'https://s3-us-west-2.amazonaws.com/media.sense/media/tmp/2025ed4790f640259ff925c340c155e3.png',
    og_image:
      'https://s3-us-west-2.amazonaws.com/media.sense/media/tmp/a1b767d700e34f7b8b20bf896e30da3d.png',
    logo:
      'https://s3-us-west-2.amazonaws.com/media.sense/media/tmp/00428a62e7ca4c23b34d87903a071644.png',
    favicon:
      'https://s3-us-west-2.amazonaws.com/media.sense/media/tmp/6b3f6396b10a41638dd7f64b557ab6c5.png',
    display_name: 'SenseHQ',
    color: '#FFFFFA',
    button_color: '#007faf',
    chatbot_banner_message: {
      blocks: [
        {
          key: '8apnk',
          text:
            '[Privacy Policy](https://www.terrastaffinggroup.com/privacy-policy/) ',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [
            {
              offset: 0,
              length: 68,
              key: 0,
            },
          ],
          data: {},
        },
        {
          key: '6apnk',
          text:
            'More things about private policy but really this is just to test the second line thing and hope it shows properly yay ok we should be there now',
          type: 'unstyled',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ],
      entityMap: {
        '0': {
          type: 'HYPERLINK',
          mutability: 'IMMUTABLE',
          data: {
            label: 'Privacy Policy',
            url: 'https://www.terrastaffinggroup.com/privacy-policy/',
          },
        },
      },
    },
  },
  agency_id: '3384364471276985052',
};

// @ts-ignore
const waitStartStub = {
  messages: [
    {
      message_id: '1',
      type: 'plain-text',
      text:
        "Hello, Kyle! I'm Reva, your recruiting assistant. I\u2019m here to help Bonney Staffing gather some additional kylejwarren.com information for a job application",
      recipient_id: 'ie6sZu5TN0oMf4XtjN27U',
      metadata: {event_type: 'plain-text'},
    },

    // {
    //   message_id: '2',
    //   type: 'lat-wait-start',
    //   recipient_id: 'ie6sZu5TN0oMf4XtjN27U',
    //   text: 'joining queue...',
    //   metadata: {
    //     event_type: 'lat-wait-start',
    //     connectionMessage: 'Agent ALEX has joined the chat',
    //   },
    // },

    // // this comes from websocket
    // {
    //   message_id: '3',
    //   text: 'Agent Alex is joining your chat...',
    //   recipient_id: '2',
    //   type: 'lat-agent-join',
    //   metadata: {
    //     event_type: 'lat-agent-join',
    //     agent_id: '__AGENT', // could be anything
    //     agent_handle: 'Pizza Bot',
    //     avatar: 'https://cipro.generic.cx/pizza_72.png', // some gravatar or static url for now
    //   },
    // },

    // // comes from websocket
    // {
    //   message_id: '4',
    //   recipient_id: '1',
    //   text: 'Hi this is alex, are you looking to forklift',
    //   type: 'lat-agent-message',
    //   agent_id: '__AGENT',
    //   metadata: {
    //     event_type: 'lat-agent-message',
    //   },
    // },

    //  // when we hit this lat-agent-drop, we need to trigger
    //  // a request from the client to fetch the next nlu message
    //  // is this just an "advance" ping we hit nlu/send?
    // {
    //   message_id: '5',
    //   text: "Bye!",
    //   type: 'lat-agent-drop',
    //   recipient_id: '1',
    //   metadata: {
    //     event_type: 'lat-agent-drop',
    //     agent_id: '__AGENT',
    //   },
    // },

    // {
    //   message_id: '6',
    //   type: 'plain-text',
    //   text:
    //     "Good talk!",
    //   recipient_id: 'ie6sZu5TN0oMf4XtjN27U',
    //   metadata: {event_type: 'plain-text'},
    // }
  ],
  ...debugConversation,
};

const BaseView = ({
  chatId,
  isSms,
  isPreview,
  handleLogoClick,
  flowBrandSettings,
  inboundDisplayMode,
  maximizeInboundDisplay,
  isMobile,
  side,
  customParams,
  loadTranscript,
  qnOverSms = false,
  jobSelected = false,
}: {
  chatId: ChatId;
  isSms: boolean;
  isPreview: boolean;
  handleLogoClick?: () => void;
  flowBrandSettings?: BrandingSettings;
  handleChangeDisplayMode?: (displayMode: InboundDisplayMode) => void;
  inboundDisplayMode?: InboundDisplayMode;
  maximizeInboundDisplay: () => void;
  isMobile?: boolean;
  side: 'left' | 'right';
  customParams?: {[key: string]: string};
  loadTranscript: boolean;
  qnOverSms?: boolean;
  jobSelected?: boolean;
}) => {
  const [state, dispatch] = useThunkReducer(reduceChatbot, {
    // TODO: undo this change once dev testing is done
    // ...defaultState,
    ...initialState,
    chatId,
    isSms,
    muteChime: false,
  });

  const sock = useLATWS(
    dispatch,
    state.sessionId,
    ['lat_wait', 'lat'].includes(state.mode),
    state.webSocketUrl,
  );

  const {conversation, error, muteChime} = state;

  const [branding, setBranding] = React.useState<BrandingSettings>(
    flowBrandSettings ?? {},
  );
  let shouldPlayChime = Boolean(branding.chatbot_audio_enabled) && !muteChime;

  const flowFaqEnabled = conversation && conversation.flow.show_top_faqs;

  React.useEffect(() => {
    // this conditional is basically a pragma
    if (process.env.NODE_ENV === 'development') {
      try {
        localStorage.chatbotState = stringify(state);
      } catch (err) {
        // looks like localStorage is disabled
        console.error(err);
      }
    }
  }, [state]);

  const releaseFlagsResource = useResourceApi<{[flag: string]: boolean}>(
    '/product-flags/release-flags',
    {api: senseApi},
    [],
  );

  // NOTE (kyle): it's not worth it to show an error here since the
  // info we get from this api is not essential to completing a
  // conversation.
  const chatbotApiV2 = Boolean(releaseFlagsResource.result?.chatbot_api_v2);
  const chatbotFaqEnabled = Boolean(releaseFlagsResource.result?.chatbot_faq);

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      //@ts-ignore
      window._dispatch = dispatch;
      //@ts-ignore
      window._start = (conv: ApiConversationStart) =>
        playChimeAndDispatch(shouldPlayChime, () =>
          dispatch({type: 'start_conversation', payload: conv}),
        );
      //@ts-ignore
      window._receive = (conv: ApiConversation) =>
        playChimeAndDispatch(shouldPlayChime, () =>
          dispatch({type: 'receive_conversation', payload: conv}),
        );
      // @ts-ignore
      window._bootstrap = () => {
        // @ts-ignore
        dispatch({type: 'start_conversation', payload: waitStartStub});
      };
      // @ts-ignore
      window._receiveSingle = (message: ApiMessage) =>
        playChimeAndDispatch(shouldPlayChime, () => {
          const payload: ApiConversation = {messages: [message]};
          dispatch({type: 'receive_conversation', payload});
        });

      // @ts-ignore
      window._reset = () => {
        delete localStorage.currentId;
        delete localStorage.chatbotState;
        window.location.reload();
      };
    }

    if (!conversation && !releaseFlagsResource.isLoading) {
      // NOTE (kyle): this should only happen once.
      api.setBasePath('/api/nlu');

      const params: {
        channel: 'sms' | 'web';
        code?: string;
        flow_id?: string;
        preview: boolean;
        replacements?: {[key: string]: string};
      } = {
        channel: isSms ? 'sms' : 'web',
        preview: isPreview,
        // for now, replacements passthrough but more data could be
        // included that aren't specifically passthrough
        replacements: customParams,
      };

      if (chatId.type === 'chat_code') {
        params.code = chatId.value;
      } else {
        params.flow_id = chatId.value;
      }

      const apiCalls = [api.post('/start', params)];

      // the conversation was already happening in some bot and remainder of the conversation
      // is part of the same conversation, with same id
      if (loadTranscript && customParams) {
        apiCalls.push(
          genericBotApi.get(
            `/bot/conversations/${customParams['external/s_cid']}/preview`,
          ),
        );
      }

      Promise.allSettled(apiCalls).then(resp => {
        const [conversationData, transcriptData] = resp;

        // processing start API response
        if (conversationData) {
          if (conversationData.status === 'fulfilled') {
            const conversation = conversationData.value;
            setBranding(conversation.branding_settings);
            // b/c this effect runs _once_ we re-init shouldPlayChime here
            // it will get updated outside the effect anyway once the component rerenders
            // but it will be incorrect for the playChimeAndDispatch call
            shouldPlayChime =
              Boolean(conversation.branding_settings.chatbot_audio_enabled) &&
              !muteChime;

            // set defaults
            window?.gtag?.('set', {agency_id: conversation.agency_id});

            window?.gtag?.('event', 'start_conversation', {shouldPlayChime});

            dispatch({
              type: 'receive_session_id',
              payload: qnOverSms
                ? customParams?.['external/s_cid'] || ''
                : conversation.conversation_id,
            });

            playChimeAndDispatch(shouldPlayChime, () => {
              dispatch({
                type: 'start_conversation',
                payload: qnOverSms
                  ? {
                      ...conversation,
                      conversation_id: customParams?.['external/s_cid'] || '',
                      messages: [],
                    }
                  : conversation,
              });
            });

            try {
              faqApi.get('', {agency_id: conversation.agency_id}).then(
                data => {
                  dispatch({type: 'receive_faqs', payload: data});
                },
                err => {
                  console.warn('could not fetch faqs', err);
                },
              );
            } catch (err) {}
          } else {
            dispatch({type: 'receive_error', payload: conversationData.reason});
          }
        }

        // processing preview API response
        if (transcriptData) {
          if (transcriptData.status === 'fulfilled') {
            dispatch({
              type: 'preview_receive_success_action',
              payload: transcriptData.value,
            });
          } else {
            dispatch({
              type: 'preview_receive_error_action',
              payload: 'Error loading conversation history',
            });
          }
        }
      });
    }
  }, [
    conversation,
    chatId,
    dispatch,
    isSms,
    releaseFlagsResource.isLoading,
    chatbotApiV2,
    isPreview,
  ]);

  React.useEffect(() => {
    if (shouldPlayChime) {
      playChime();
    }
  }, [shouldPlayChime]);

  React.useEffect(() => {
    const brandingDefaults = {
      color: '#007FAF',
      button_color: '#007FAF',
      chatbot_bot_name: 'Reva',
      chatbot_bot_tagline: 'Recruiting Assistant',
      chatbot_bubble_color: '#F7F7F7',
      chatbot_font_color: '#000000',
      chatbot_initial_greeting: '👋  Hello! I am here to help.',
      chatbot_audio_enabled: false, //due to us filtering out "null"s from the api, this needs to be set to false. If there is a true value being sent from branding settings, this will be overwritten
    };

    let brandingSettings;
    if (flowBrandSettings) {
      // arriving from a sourcing bot that supplied a branding on mount
      brandingSettings = flowBrandSettings;
    } else if (conversation?.branding_settings) {
      // a web bot whose conversation included branding settings
      brandingSettings = conversation.branding_settings;
    }

    if (brandingSettings != null) {
      const filteredSettings = omitBy(brandingSettings, val => val === null);
      const mergedBranding = {...brandingDefaults, ...filteredSettings};
      setBranding(mergedBranding);
      // this renames the chatbot from reva after we pull the brand settings
      // b/c we can't otherwise do this without doing some ssr CHAT-2760
      document.title = mergedBranding.chatbot_bot_name;
    }
  }, [conversation, flowBrandSettings]);

  if (inboundDisplayMode === 'min') {
    return (
      <BaseMinView
        flowBrandSettings={branding}
        conversation={conversation}
        side={side}
        handleLogoClick={handleLogoClick}
      />
    );
  }

  return error ? (
    <ErrorPage error={error} />
  ) : !conversation ? (
    <Loading
      color={flowBrandSettings ? flowBrandSettings.button_color : undefined}
    />
  ) : (
    <ChatbotContext.Provider value={{state, dispatch}}>
      <BrandingContext.Provider value={branding}>
        <ConversationView
          chatId={chatId}
          conversation={conversation}
          state={state}
          dispatch={dispatch}
          chatbotApiV2={chatbotApiV2}
          handleLogoClick={handleLogoClick}
          isPreview={isPreview}
          inboundDisplayMode={inboundDisplayMode}
          maximizeInboundDisplay={maximizeInboundDisplay}
          flowBrandSettings={flowBrandSettings}
          isMobile={isMobile}
          chatbotFaqEnabled={Boolean(chatbotFaqEnabled && flowFaqEnabled)}
          qnOverSms={qnOverSms}
          jobSelected={jobSelected}
        />
      </BrandingContext.Provider>
    </ChatbotContext.Provider>
  );
};

export default BaseView;
