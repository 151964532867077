import * as React from 'react';

import {ChatbotDispatch, ApiMessage} from 'types';

export function useLATWS(
  dispatch: ChatbotDispatch,
  conversationId: string,
  shouldConnect: boolean,
  webSocketUrl: string | null,
) {
  const wsPath = `${webSocketUrl}?conversation_id=${conversationId}`;
  const ws = React.useRef<WebSocket | null>(null);

  const handleOpen = (evt: Event) => {
    // pass
    console.log('[lat-ws]: connected');
  };

  const handleClose = (evt: CloseEvent) => {
    console.log('[lat-ws] disconnected');

    // dispatch()
  };

  const handleError = (evt: Event) => {
    // dispatch()
    console.warn('[lat-ws] socket error:', evt);
  };

  React.useEffect(() => {
    const handleMessage = (evt: MessageEvent) => {
      let {data} = evt;

      try {
        data = JSON.parse(data) as ApiMessage | string;
      } catch (err) {
        console.warn(`[lat-ws] could not parse ws message "${data}"`);
      }
      if (typeof data === 'string') {
        // this means we weren't able to deserialize the message from
        // the live chat router because it was malformed.
        console.error(`[lat-ws]: could not parse lcr message: ${data}`);
      } else if (typeof data === 'object') {
        // for now, we shoehorn data we get into existing message formats.
        // eventually, the data we get from the lcr will match the existing
        // message formats and we can deal with them in the reducer instead.

        window?.gtag('event', 'lat_event', {type: data.event_type});

        if (data.event_type === 'agent_join') {
          data = {
            type: 'lat-agent-join',
            text: data.text,
            conversation_id: data.conversation_id,
            agency_id: data.agency_id,
            metadata: {
              event_type: 'lat-agent-join',
              agent_id: data.agent_id,
              agent_handle: data.agent_handle,
              agent_avatar: data.agent_avatar,
            },
          };
        } else if (data.event_type === 'agent_msg') {
          data = {
            type: 'lat-agent-message',
            conversation_id: data.conversation_id,
            text: data.text,
            agency_id: data.agency_id,
            metadata: {
              event_type: 'lat-agent-message',
              agent_id: data.agent_id,
            },
          };
        } else if (data.event_type === 'agent_drop') {
          data = {
            type: 'lat-agent-drop',
            conversation_id: data.conversation_id,
            agency_id: data.agency_id,
            text: data.text,
            metadata: {
              event_type: 'lat-agent-drop',
              agent_id: data.agent_id,
            },
          };
        } else if (data.speaker === 'control' && data.meta === '') {
          data = {...specialMessages['lat-agent-join'], text: data.text};
        } else if (data.speaker === 'control' && data.meta === 'downgrade') {
          data = {...specialMessages['lat-agent-drop'], text: data.text};
        } else if (data.speaker === 'agent') {
          data = {...specialMessages['lat-agent-message'], text: data.text};
        }
        //
        dispatch({type: 'receive_conversation', payload: {messages: [data]}});
      }
    };

    if (webSocketUrl == null) {
      return;
    }
    if (shouldConnect) {
      if (
        !(ws.current instanceof WebSocket) ||
        // @ts-ignore readystate matches type of closed/closing
        [WebSocket.CLOSED, WebSocket.CLOSING].includes(ws.current.readyState)
      ) {
        console.log(`[lat-ws] connecting to '${wsPath}'`);
        ws.current = new WebSocket(wsPath);

        ws.current.addEventListener('open', handleOpen);
        ws.current.addEventListener('message', handleMessage);
        ws.current.addEventListener('close', handleClose);
        ws.current.addEventListener('error', handleError);
      } else if (
        // @ts-ignore readystate matches type of connecting/open
        [WebSocket.CONNECTING, WebSocket.OPEN].includes(ws.current.readyState)
      ) {
        console.log(
          '[lat-ws] already connected, not reconnecting',
          ws.current.readyState,
        );
      }
    } else {
      if (ws.current) {
        ws.current.removeEventListener('open', handleOpen);
        ws.current.removeEventListener('message', handleMessage);
        ws.current.removeEventListener('close', handleClose);
        ws.current.removeEventListener('error', handleError);
        if (ws.current.readyState === 1) {
          ws.current?.close();
        }
        ws.current = null;
      }
    }
    return () => {
      if (ws.current) {
        ws.current.removeEventListener('open', handleOpen);
        ws.current.removeEventListener('message', handleMessage);
        ws.current.removeEventListener('close', handleClose);
        ws.current.removeEventListener('error', handleError);
        if (ws.current.readyState === 1) {
          ws.current?.close();
        }

        ws.current = null;
      }
    };
  }, [shouldConnect, wsPath, dispatch, webSocketUrl]);

  return ws.current;
}

const specialMessages = {
  'lat-wait-start': {
    message_id: '2',
    type: 'lat-wait-start',
    recipient_id: 'ie6sZu5TN0oMf4XtjN27U',
    text: 'joining queue...',
    metadata: {
      event_type: 'lat-wait-start',
      connectionMessage: 'Agent ALEX has joined the chat',
    },
  },

  // this comes from websocket
  'lat-agent-join': {
    message_id: '3',
    text: 'Agent Alex is joining your chat...',
    recipient_id: '2',
    type: 'lat-agent-join',
    metadata: {
      event_type: 'lat-agent-join',
      agent_id: '__AGENT', // could be anything
      agent_handle: 'Pizza Bot',
      avatar: 'https://cipro.generic.cx/pizza_72.png', // some gravatar or static url for now
    },
  },

  // comes from websocket
  'lat-agent-message': {
    message_id: '4',
    recipient_id: '1',
    text: 'Hi this is alex, are you looking to forklift',
    type: 'lat-agent-message',
    agent_id: '__AGENT',
    metadata: {
      event_type: 'lat-agent-message',
    },
  },

  // when we hit this lat-agent-drop, we need to trigger
  // a request from the client to fetch the next nlu message
  // is this just an "advance" ping we hit nlu/send?
  'lat-agent-drop': {
    message_id: '5',
    text: 'Bye!',
    type: 'lat-agent-drop',
    recipient_id: '1',
    metadata: {
      event_type: 'lat-agent-drop',
      agent_id: '__AGENT',
    },
  },
};
