import isPlainObject from 'lodash/isPlainObject';
import map from 'lodash/map';

function classify(
  ...args: Array<
    {[className: string]: unknown} | Array<unknown> | string | null | void
  >
): string {
  return args
    .map(arg => {
      if (isPlainObject(arg)) {
        // @ts-ignore hello
        arg = map(arg, (test, name: string) => (test ? name : null)) as Array<
          unknown
        >;
      }
      return arg;
    })
    .reduce<unknown[]>((array, current) => {
      if (Array.isArray(current)) {
        array.push(...current);
      } else {
        array.push(current);
      }
      return array;
    }, [])
    .filter(val => val)
    .join(' ');
}

export default classify;
