import {useEffect, useState} from 'react';

import {Api, ApiError} from 'utils/api';
import botApi from 'utils/bot-api';

type ApiQuery = {[key: string]: unknown};

type ApiUrl = string | {pathname: string; query?: ApiQuery} | null | void;

type ResponseState<R> = {response: R} | {error: ApiError} | null;

type ResourceState<R> =
  | {isLoading: true; result: null; error: null}
  | {isLoading: false; result: R; error: null}
  | {isLoading: false; error: ApiError; result: null};

type StubResource<T> =
  | {
      isLoading: true;
      result: null;
    }
  | {isLoading: false; result: T};

export function useApi<R>(
  url: ApiUrl,
  {
    handleResponse,
    handleError,
    api = botApi,
  }: {
    handleResponse: (response: R) => unknown;
    handleError?: (error: ApiError) => unknown;
    api?: Api;
  },
  deps?: Array<unknown>,
) {
  let pathname: string | void;
  let query: ApiQuery | undefined;
  if (url instanceof Object) {
    pathname = url.pathname;
    query = url.query;
  } else if (typeof url === 'string') {
    pathname = url;
  }

  if (!deps) {
    deps = [pathname, query];
  }

  useEffect(() => {
    if (pathname) {
      api.get(pathname, query).then(
        response => {
          handleResponse(response as R);
        },
        error => {
          if (handleError) {
            handleError(error);
          } else {
            throw error;
          }
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function useStateApi<R>(
  url: ApiUrl,
  options?: {api?: Api},
  deps?: unknown[],
): ResponseState<R> {
  const [response, setResponse] = useState<ResponseState<R>>(null);

  useApi<R>(
    url,
    {
      ...options,
      handleResponse: response => setResponse({response}),
      handleError: error => setResponse({error}),
    },
    deps,
  );

  return response;
}

export function useResourceApi<R>(
  url: ApiUrl,
  options?: {api?: Api},
  deps?: unknown[],
): ResourceState<R> {
  const [response, setResponse] = useState<ResourceState<R>>({
    isLoading: true,
    result: null,
    error: null,
  });

  useApi<R>(
    url,
    {
      ...options,
      handleResponse: result =>
        setResponse({isLoading: false, result, error: null}),
      handleError: error =>
        setResponse({isLoading: false, error, result: null}),
    },
    deps,
  );

  return response;
}

export function useTransformedStateApi<R, S>(
  url: ApiUrl,
  {
    transform,
  }: {
    transform: (responseData: R) => S;
  },
  deps?: unknown[],
): S | null {
  const [response, setResponse] = useState<S | null>(null);

  useApi<R>(
    url,
    {
      handleResponse: responseData => {
        setResponse(transform(responseData));
      },
    },
    deps,
  );

  return response;
}

//TODO(marcos): errors currently handled by useApi, but
//should try to find some better approach here
export function useStateResourceApi<T>(
  url: ApiUrl,
  options: {api?: Api},
  deps?: unknown[],
): StubResource<T> {
  const [response, setResponse] = useState<StubResource<T>>({
    isLoading: true,
    result: null,
  });

  useApi<T>(
    url,
    {
      ...options,
      handleResponse: responseData => {
        setResponse({isLoading: false, result: responseData});
      },
    },
    deps,
  );
  return response;
}

export function useStubApi<T>(
  stubData: T,
  timeout: number = 600,
): StubResource<T> {
  const [stubApiData, setStubApiData] = useState<StubResource<T>>({
    isLoading: true,
    result: null,
  });

  useEffect(() => {
    const localTimeoutId = setTimeout(() => {
      setStubApiData({isLoading: false, result: stubData});
    }, timeout);

    return () => {
      if (localTimeoutId) {
        clearTimeout(localTimeoutId);
      }
    };
  }, [stubData, timeout]);

  return stubApiData;
}
