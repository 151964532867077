// @flow

import * as React from 'react';

type Props = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  disabled?: unknown;
  onKeyPress?: (event: React.KeyboardEvent<HTMLElement>) => unknown;
  onClick?: (event: React.SyntheticEvent<HTMLElement>) => unknown;
  onPointerDown?: (event: React.SyntheticEvent<HTMLElement>) => unknown;
};

/**
 * our default Button class
 *
 * We use a div because `<button>` has a lot of style quirks that differ
 * between browsers
 *
 * Note that onClick does NOT take a MouseEvent and instead just a regular Event.
 * This is because the keyup event can trigger the click event, and we'd rather
 * not use the `.click()` method.
 */
function UnstyledButton(
  {children, className, disabled, onKeyPress, onClick, ...props}: Props,
  ref: React.Ref<HTMLDivElement>,
) {
  return (
    <div
      className={className}
      ref={ref}
      role="button"
      // @ts-ignore disabled is a prop of a role=button
      disabled={Boolean(disabled)}
      tabIndex={!disabled ? 0 : undefined}
      onKeyPress={event => {
        if (disabled) {
          event.preventDefault();
        } else {
          if (onKeyPress) {
            onKeyPress(event);
          }

          if (
            onClick &&
            !event.isDefaultPrevented() &&
            (event.key === 'Enter' || event.key === ' ')
          ) {
            onClick(event);
          }
        }
      }}
      onClick={event => {
        if (disabled) {
          event.preventDefault();
        } else if (onClick) {
          onClick(event);
        }
      }}
      {...props}
    >
      {children}
    </div>
  );
}

export default React.forwardRef<HTMLDivElement, Props>(UnstyledButton);
