import {JobMatchMessage} from 'types';

const isJobMatchQuestionNode = (accessory: JobMatchMessage) => {
  return accessory.allow_application === true;
};

const isJobMatchMessageNode = (accessory: JobMatchMessage) => {
  return accessory.allow_application === false;
};

const getJobMatchSummaryMessage = (matchCount: number) => {
  return `${matchCount} matching ${matchCount > 1 ? 'jobs' : 'job'}`;
};

export {
  isJobMatchQuestionNode,
  isJobMatchMessageNode,
  getJobMatchSummaryMessage,
};
